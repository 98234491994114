<template>
	<div class="animated fadeIn">
		<div class="row">
			<div class="col">
				<b-button
					:variant="!showFilters ? 'primary' : 'secondary'"
					class="mx-1 bg-primary-alt"
					@click="showFilters = !showFilters">
					<i
						class="fa fa-filter"
						aria-hidden="true" />
				</b-button>
				<b-button
					v-if="hasAccess('NewCorporate')"
					variant="success"
					class="float-right"
					@click="$router.push({ name: 'NewCorporate'});">
					{{ translate('create') }}
				</b-button>
			</div>
		</div>
		<hr>
		<data-filter
			:display="showFilters"
			get-by="name"
			@submit="getDataFiltered"
			@clear="clearFilters">
			<template slot="form">
				<div class="row">
					<div class="col-md-3 col-sm-6 col-xs-12">
						<search
							v-model="filters.username"
							:label-text="translate('autocomplete_label')"
							:default-params="{role:'corporate'}"
							:endpoint="endpoint"
							:empty-result-message="translate('data_not_found')"
							:placeholder="'autocomplete_placeholder'"
							class="mb-3"
							q="label"
							return-key="username" />
					</div>
					<div class="col-md-3 col-sm-6 col-xs-12">
						<div class="form-group">
							<label
								for="countryName"
								class="label">{{ translate('country') }}</label>

							<select
								id="countryName"
								v-model="filters.country"
								:disabled="staffCountry"
								name="country"
								class="form-control">
								<template v-if="!staffCountry">
									<option :value="undefined">
										{{ translate('all') }}
									</option>
									<option
										v-for="country in countries"
										:key="country.attributes.code"
										:value="country.attributes.code">
										{{ translate(country.attributes.code.toLowerCase()) }}
									</option>
								</template>
								<template v-if="staffCountry">
									<option :value="countryCode">
										{{ translate(countryCode.toLowerCase()) }}
									</option>
								</template>
							</select>
						</div>
					</div>
					<div class="col-md-3 col-sm-6 col-xs-12">
						<div class="form-group">
							<label
								for="status"
								class="label">{{ translate('status') }}</label>
							<select
								id="status"
								v-model="filters.status"
								name="status"
								class="form-control">
								<option :value="undefined">
									{{ translate('all') }}
								</option>
								<option
									v-for="status in statuses"
									:key="status"
									:value="status">
									{{ translate(status) }}
								</option>
							</select>
						</div>
					</div>
					<div class="col-md-3 col-sm-6 col-xs-12">
						<div
							class="form-group">
							<label
								for="access_group"
								class="label">{{ translate('access_group') }}</label>
							<select
								id="access_group"
								v-model="filters.access_group"
								name="access_group"
								class="form-control">
								<option :value="undefined">
									{{ translate('all') }}
								</option>
								<option
									v-for="accessGroup in accessGroups"
									:key="accessGroup"
									:value="accessGroup">
									{{ translate(accessGroup) }}
								</option>
							</select>
						</div>
					</div>
				</div>
			</template>
		</data-filter>
		<div class="table-responsive">
			<table class="table table-hover table-striped text-nowrap">
				<thead>
					<tr>
						<th class="border-top-0">
							#
						</th>
						<th
							class="border-top-0 pointer"
							@click="sortByField('id')">
							{{ translate('user_id') }}
							<sort field="id" />
						</th>
						<th
							class="border-top-0 pointer"
							@click="sortByField('username')">
							{{ translate('username') }}
							<sort field="username" />
						</th>
						<th
							class="border-top-0 pointer"
							@click="sortByField('first_name')">
							{{ translate('name') }}
							<sort field="first_name" />
						</th>
						<th
							class="border-top-0 pointer"
							@click="sortByField('email')">
							{{ translate('email') }}
							<sort field="email" />
						</th>
						<th
							class="border-top-0 pointer"
							@click="sortByField('country')">
							{{ translate('country') }}
							<sort field="country" />
						</th>
						<th
							class="border-top-0 pointer"
							@click="sortByField('status')">
							{{ translate('status') }}
							<sort field="status" />
						</th>
						<th
							class="border-top-0 pointer">
							{{ translate('access_group') }}
						</th>
						<th
							class="border-top-0 pointer"
							@click="sortByField('tfa_enabled')">
							{{ translate('two_factor_auth') }}
							<sort field="tfa_enabled" />
						</th>
						<th class="border-top-0 text-center">
							{{ translate('options') }}
						</th>
					</tr>
				</thead>
				<tbody v-if="!loading && hasData">
					<tr
						v-for="(item, index) in data"
						:key="index"
						class="text-nowrap">
						<td class="align-middle">
							{{ ((pagination.per_page * pagination.current_page) - pagination.per_page) + (index + 1) }}
						</td>
						<td class="align-middle">
							<span class="badge badge-primary">{{ item.id }}</span>
						</td>
						<td class="align-middle">
							{{ item.attributes.username }}
						</td>
						<td class="align-middle">
							{{ item.attributes.name }}
						</td>
						<td class="align-middle">
							{{ item.attributes.email }}
						</td>
						<td class="align-middle">
							<img
								v-b-tooltip.hover
								class="ml-3"
								:title="getCountry(item.attributes.country)"
								:src="item.attributes.country ? require(`@/assets/images/common/countries/select/${item.attributes.country ? item.attributes.country.toUpperCase() : 'default'}.png`) : ''"
								:alt="getCountry(item.attributes.country)">
						</td>
						<td class="align-middle">
							{{ translate(item.attributes.status.toLowerCase()) }}
						</td>
						<td class="align-middle">
							{{ translate(item.attributes.access_group) }}
						</td>
						<td class="align-middle text-center">
							{{ translate(`${!!item.attributes.tfa_enabled}`) }}
						</td>

						<td class="text-center align-middle">
							<template
								v-if="showButtons(item)">
								<b-button
									v-if="hasAccess('CorporatesPermissions', { corporateId: item.id }) && !item.attributes.has_access_group"
									v-b-tooltip.hover
									:title="translate('update_thing', {name: translate('permissions')})"
									variant="primary"
									class="mr-1 bg-primary-alt"
									@click="$router.push({ name: 'CorporatesPermissions', params: { corporateId: item.id, username: item.attributes.username, hasAccessGroup: item.attributes.has_access_group } })">
									<i class="fa fa-lock" />
								</b-button>
								<b-button
									v-if="hasAccess('UpdateCorporate', { corporateId: item.id })"
									v-b-tooltip.hover
									:title="translate('update_thing', {name: translate('corporate')})"
									variant="primary"
									class="mr-1 bg-primary-alt"
									@click="$router.push({ name: 'UpdateCorporate', params: { corporateId: item.id } });">
									<i
										class="fa fa-user-edit"
										aria-hidden="true" />
								</b-button>
								<b-button
									v-if="hasAccess('UpdateCorporatePassword', { corporateId: item.id })"
									v-b-tooltip.hover
									:title="translate('update_thing', {name: translate('password')})"
									variant="primary"
									class="bg-primary-alt"
									@click="$router.push({ name: 'UpdateCorporatePassword', params: { corporateId: item.id, username: item.attributes.username } });">
									<i
										class="fa fa-key"
										aria-hidden="true" />
								</b-button>
							</template>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
		<is-loading
			:loading-label="translate('loading')"
			:no-data-label="translate('data_not_found')"
			:loading="loading"
			:has-data="hasData" />
		<b-pagination
			v-if="pagination.total_pages > 1"
			v-model="pagination.current_page"
			:total-rows="pagination.total"
			:per-page="pagination.per_page"
			align="center"
			@change="getDataFiltered" />
	</div>
</template>
<script>

import { PAGINATION } from '@/settings/RequestReply';
import { BASIC as statuses } from '@/settings/Statuses';
import { ACCESS_GROUPS as accessGroups } from '@/settings/Permissions';
import {
	Countries, Profile, Users, Tooltip, Grids, AccessGroups,
} from '@/translations';
import FiltersParams from '@/mixins/FiltersParams';
import DataFilter from '@/components/DataFilter';
import Corporate from '@/util/User';
import Country from '@/util/Country';
import { SEARCH_USERS } from '@/config/endpoint';

const { endpoint } = SEARCH_USERS;

export default {
	name: 'Corporates',
	messages: [Countries, Profile, Users, Tooltip, Grids, AccessGroups],
	components: {
		DataFilter,
	},
	mixins: [FiltersParams],
	data() {
		return {
			corporate: new Corporate(),
			statuses,
			countryData: new Country(),
			endpoint,
			accessGroups,
		};
	},
	computed: {
		loading() {
			return !!this.corporate.data.loading;
		},
		errors() {
			return this.corporate.data.errors;
		},
		pagination() {
			return this.corporate.data.pagination;
		},
		data() {
			try {
				const { data } = this.corporate.data.response.data;
				return data;
			} catch (error) {
				return [];
			}
		},
		hasData() {
			const response = this.data.length;
			return !!response;
		},
		countries() {
			try {
				return this.countryData.data.response.data.data;
			} catch (error) {
				return [];
			}
		},
		staffCountry() {
			return this.$user.details().country !== undefined;
		},
		countryCode() {
			return this.$user.details().country.iso_code_2;
		},
	},
	watch: {
		'filters.status': function (value) { // eslint-disable-line func-names
			this.filters.status = typeof value === 'undefined' || value === '' ? undefined : value;
		},
		'filters.countryId': function (value) { // eslint-disable-line func-names
			this.filters.countryId = typeof value === 'undefined' || value === '' ? undefined : value;
		},
	},
	mounted() {
		this.countryData.getCountries();
		this.corporate.getCorporates();
		if (this.staffCountry) {
			this.filters.country = this.countryCode;
		}
	},
	methods: {
		getDataFiltered(page) {
			if (typeof page === 'object') {
				this.filters = { ...this.filters, ...page };
			}
			const { query } = this.$route;
			const options = { ...query, ...this.filters };
			if (typeof page === 'number') {
				options[PAGINATION] = page;
			}

			this.corporate.getUsers(options);
		},
		showButtons(item) {
			return (this.hasAccess('CorporatesPermissions', { corporateId: item.id })
				|| this.hasAccess('UpdateCorporate', { corporateId: item.id })
				|| this.hasAccess('UpdateCorporatePassword', { corporateId: item.id }));
		},
		getCountry(item) {
			try {
				return this.translate(item.toLowerCase());
			} catch (error) {
				return '';
			}
		},
	},
};
</script>
